import { Component, OnInit } from '@angular/core';
import { PersonDetailsFacadeService } from '@qivos/qivoscloud-ion-components';
import { Address, Email, Person } from '@qivos/qivoscloud-ng-api';
import {
  initMobileNumber,
  searchPersonByTelephone,
  updatePerson,
} from 'src/app/actions/member-details-form.actions';
import { MemberDetailsFacadeService } from 'src/app/services/member-details-from.facade.service';

@Component({
  selector: 'app-member-details-form',
  templateUrl: './member-details-form.page.html',
  styleUrls: ['./member-details-form.page.scss'],
})
export class MemberDetailsFormPage implements OnInit {
  constructor(
    public PersonFacade: PersonDetailsFacadeService,
    public MemberFacade: MemberDetailsFacadeService
  ) {}

  person: Person = new Person();

  ngOnInit() {
    const person = this.MemberFacade.qcService.getDecodedToken();
    if (person !== null) {
      this.MemberFacade.dispatch(
        searchPersonByTelephone({ mobileNumber: person.mobileNumber })
      );
      this.MemberFacade.dispatch(
        initMobileNumber({ mobileNumber: person.mobileNumber })
      );
    }
  }

  updatePerson() {
    //     const nameDay = this.PersonFacade.nameDate.split('-');

    if (this.PersonFacade.email.length > 0) {
      this.person.emailList?.push(
        new Email({ emailAddress: this.PersonFacade.email, isPrimary: true })
      );
    }

    if (this.PersonFacade.selectedRegionCode.length > 0) {
      this.person.addressList?.push(
        new Address({
          addressLine2: '',
          addressType: 'PRIMARY_PERSON_ADDRESS',
          town: this.PersonFacade.selectedTown,
          regionCode: this.PersonFacade.selectedRegionCode,
          countryCode: 'gr',
        })
      );
    }

    this.person.firstName = this.PersonFacade.firstName;
    this.person.lastName = this.PersonFacade.lastName;
    this.person.salutationCode = this.PersonFacade.gender.Value;
    this.person.gender = this.PersonFacade.gender.Value;
    this.person.dateOfBirth = this.PersonFacade.dateOfBirth;
    this.person.nameDayDay = this.PersonFacade.nameDateDay;
    this.person.nameDayMonth = this.PersonFacade.nameDateMonth;
    this.MemberFacade.dispatch(updatePerson({ person: this.person }));
  }
}
