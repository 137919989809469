import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoyaltyMembership, QivosCloudService } from '@qivos/qivoscloud-ng-api';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  searchPersonByTelephone,
  searchPersonByTelephoneFail,
  searchPersonByTelephoneSuccess,
  updateMemberFailed,
  updateMemberSuccess,
  updatePerson,
  updatePersonFailed,
  updatePersonSuccess,
} from '../actions/member-details-form.actions';

@Injectable()
export class MemberDetailsEffects {
  constructor(
    private actions$: Actions,
    public qcService: QivosCloudService,
    private router: Router
  ) {}

  authByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchPersonByTelephone),
      switchMap(({ mobileNumber }) =>
        this.qcService.searchPersonByTelephone(mobileNumber).pipe(
          switchMap((res) =>
            of(searchPersonByTelephoneSuccess({ persons: res?.payload.data }))
          ),
          catchError((error) => of(searchPersonByTelephoneFail(error)))
        )
      )
    )
  );

  updatePerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePerson),
      switchMap(({ person }) =>
        this.qcService.updatePerson(person).pipe(
          switchMap((res) => of(updatePersonSuccess({ person: res?.payload }))),
          catchError((error) => of(updatePersonFailed(error)))
        )
      )
    )
  );

  personQCCode: string = '';
  loyaltyMembership: LoyaltyMembership = { memberStatus: 'FULL_REGISTRATION' };

  updateMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePersonSuccess),
      map(({ person }) => {
        this.personQCCode = person.data.QCCode as string;
      }),
      switchMap(() =>
        this.qcService
          .updateMember(this.personQCCode, this.loyaltyMembership)
          .pipe(
            switchMap(() => of(updateMemberSuccess())),
            catchError((error) => of(updateMemberFailed(error)))
          )
      )
    )
  );

  onUpdateSuccessRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(updateMemberSuccess),
        map(() => {
          this.router.navigate(['register-successfully']);
        })
      );
    },
    { dispatch: false }
  );
}
